import type { Schemas } from '#shopware';

export class Listing {
    static findProductListingBlock(section: Schemas['CmsSection']) {
        return section.blocks?.find((block) => block.type === 'product-listing') ?? null;
    }

    static getListingProducts(sections: Schemas['CmsSection'][] | undefined) {
        let listingBlock;

        if (sections) {
            for (const section of sections) {
                const currentBlock = Listing.findProductListingBlock(section);
                if (currentBlock) {
                    listingBlock = currentBlock;
                    break;
                }
            }

            const listingSlot = listingBlock?.slots[0] as Schemas['CmsSlot'];
            // @ts-ignore
            return listingSlot?.data?.listing?.elements;
        }
    }
}
