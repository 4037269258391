<script setup lang="ts">
import type { Ref } from 'vue';
import { useCmsHead } from '@/composables/useCmsHead';
import { Listing } from '~/helpers/Listing';
import type { Schemas } from '#shopware';

const { page } = usePage();
const { addCategoryCacheTag, addProductCacheTag } = useCache();
const pageCategory = ref(page.value?.category);

const { category } = useCategory(pageCategory as Ref<Schemas['Category']>);
useCmsHead(category);

addCategoryCacheTag(category.value.id);

const listingProducts = Listing.getListingProducts(category.value?.cmsPage?.sections);
if (listingProducts) {
    listingProducts.forEach((product: Schemas['Product']) => {
        addProductCacheTag(product.id);
    });
}

const { isHomePath } = useSeoPath();
const { addLastSeen, resetCurrentItem } = useLastSeen('category');
if (!isHomePath.value && pageCategory.value?.id) {
    addLastSeen(pageCategory.value.id);
}

const listingHits = ref<number | undefined>(undefined);
provide('listingHits', listingHits);

onBeforeRouteUpdate(() => {
    resetCurrentItem();
});
onBeforeRouteLeave(() => {
    resetCurrentItem();
});
</script>

<template>
    <CmsPage
        v-if="page?.cmsPage"
        :key="page.cmsPage.id"
        :content="page.cmsPage as Schemas['CmsPage']"
    />
</template>
